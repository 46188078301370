<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="complex"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      @changedPage="setPage"
      @setSearch="setSearch"
      @showRemoveComponent="showRemoveComponent"
      @showEditComponent="editComplex"
    />
    <ConfirmDialogue @remove="removeComplex" />
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import toast from "@/mixins/toast";
import {bus} from "@/main";
import {mapGetters} from "vuex";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
export default {
  name: "Complex",
  components: {ConfirmDialogue, Table},
  mixins: [toast],
  data() {
    return {
      loading: false,
      btnTitle: null,
      search: '',
      itemId: null
    }
  },
  created() {
    this.btnTitle = this.$route.meta.button.btnTitle
    bus.$on(this.btnTitle, () => {
      this.createNewComplex()
    })
    let data = {
      id: this.$route.params.id
    }
    this.getData(data)
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      complex: 'phase/complex',
      total: 'phase/complexTotal'
    }),
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('table.name'),
          sortable: true,
          sortDirection: 'desc',
          clickable: true,
          thStyle: { minWidth: '100px !important' }
          },
        { key: 'description', label: this.$t('table.description'), thStyle: { minWidth: '150px !important' } },
        { key: 'property', label: this.$t('table.property'), thStyle: { minWidth: '50px !important' } },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right'}
      ]
    },
    name() {
      return this.$t('secondSidebar.complex')
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true

      this.$store.dispatch('phase/getComplex', data).finally(() => {
        this.loading = false
      })
    },
    setFilters(filters) {
      console.log(filters)
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    createNewComplex() {
      this.$store.dispatch('phase/createNewComplex', this.$route.params.id).then(() => {
        this.makeToast('The complex was created successfully')
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
    },
    removeComplex() {
      this.$store.dispatch('phase/removeComplex', this.itemId).then(() => {
        this.makeToast(this.$t('toastMessages.complexDeleted'))
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
        .finally(() => {
          this.itemId = null
          this.toggleConfirmDialogue()
        })
    },
    showRemoveComponent(id) {
      this.itemId = id
      this.toggleConfirmDialogue()
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    editComplex(id) {
      this.$router.push({ name: 'complex.show', params: { id } })
    }
  },
  beforeDestroy() {
    bus.$off(this.btnTitle)
  },
}
</script>

<style scoped>

</style>